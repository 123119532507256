<template>
	<div style="color: #444; margin:0;font: normal 14px/20px Arial, Helvetica, sans-serif; height:100%; background-color: #fff;">
		<div style="height:auto; min-height:100%; ">
			<div style="text-align: center; width:800px; margin-left: -400px; position:absolute; top: 30%; left:50%;">
				<h1 style="margin:0; font-size:150px; line-height:150px; font-weight:bold;">404</h1>
				<h2 style="margin-top:20px;font-size: 30px;">Not Found</h2>
				<p>The page you are looking for doesn't exist!</p>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			title: '404 Not Found',
		}
	},
	methods: {
		
	},
	created() {
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		
	},
}
</script>